import React, { useState, useContext, useEffect } from "react";
import Calculator from "../../../../../img/icons/calculator.svg";
import { DateTimePicker } from "../../../../common/inputs/datePicker";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";

export default function Commencement(props) {
  const {
    data,
    handleDateChange,
    handleLiveDateChange,
    setProceedNext,
    viewShow,
    editShow,
    setData
  } = useContext(SupplierProgramContext);

  useEffect(() => {
    if (editShow) {
      const updatedData = { ...data, update: true };
      setData(updatedData);
    } else {
    }
  }, [editShow]);

  if (data?.commencement_date && data?.program_date) {
    setProceedNext(true);
  } else {
    setProceedNext(false);
  }
  const parsedDate = data?.commencement_date
  ? new Date(data.commencement_date)
  : null;

  const parsedLiveDate = data?.program_date
  ? new Date(data.program_date)
  : null;

  return (
    <div>
      <div className="row">
        <div className="col-lg-5">
          <div className="logo text-center mb-2 mt-2  ">
            <img src={Calculator} alt="Fifo Calculator" />
          </div>
          <div className="text-center">
            <h6>Commencement Dates</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. orem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </p>
          </div>
        </div>
        <div className="col-lg-7">
          <div>
            <div className="d-sm-flex w-100  py-3 align-items-center ">
              <div className="custom-control custom-radio custom-control-inline"></div>
              <div className="col-lg-10">
                <div className="border rounded-xl p-4 shadow-sm mb-3   font-weight-bold">
                  <div className="form-group p-2">
                    <label>Set Commencement Date</label>
                    <div>
                      {" "}
                      <DateTimePicker
                        name="commencement_date"
                        type="normal"
                        onChange={(date) => handleDateChange(date)}
                        selected={parsedDate}
                        format="dd/MM/y"
                        readOnly={viewShow === true}
                      ></DateTimePicker>
                    </div>
                  </div>
                  <div className="form-group p-2">
                    <label>Set the date the program is to go live</label>
                    <div>
                      {" "}
                      <DateTimePicker
                        name="program_date"
                        type="normal"
                        onChange={(date) => handleLiveDateChange(date)}
                        selected={parsedLiveDate}
                        format="dd/MM/y"
                        readOnly={viewShow === true}
                      ></DateTimePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
