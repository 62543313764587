import React, { useState, useContext, useEffect } from "react";
import Calculator from "../../../../../img/icons/calc.svg";
import { TextInput } from "../../../../common/inputs";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";

export default function SupplierCriteria(props) {
  const { handleChange, data, viewShow, editShow, setData, handleInput, validateDiscountRate } =
    useContext(SupplierProgramContext);

    useEffect(() => {
      validateDiscountRate();
    }, [handleChange]);

  useEffect(() => {
    if (editShow) {
      const updatedData = { ...data, update: true };
      setData(updatedData);
    } else {
    }
  }, [editShow]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-5">
          <div className="logo text-center mb-2 mt-2  ">
            <img src={Calculator} alt="Fifo Calculator" />
          </div>
          <div className="text-center">
            <h6>Create Supplier Criteria</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. orem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </p>
          </div>
        </div>
        <div className="col-lg-7">
          <div>
            <div className="d-sm-flex w-100  py-3 align-items-center ">
              <div className="custom-control custom-radio custom-control-inline"></div>
              <div className="col-lg-10">
                <div className="border rounded-xl p-4 shadow-sm mb-3   font-weight-bold">
                  <div className="form-group p-2">
                    <label>Enter the credit score?</label>
                    <div>
                      {" "}
                      <TextInput
                        value={data?.minimum_credit_score}
                        onChange={handleChange}
                        onInput={handleInput}
                        placeholder="Credit Score"
                        name="minimum_credit_score"
                        type="normal"
                        readonly={viewShow === true}
                      ></TextInput>
                      <span className="text-xs text-muted">
                        Enter the minimum credit score required to qualify for
                        this program
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
