import React, { useState, useContext, useEffect } from "react";
import Calculator from "../../../../../img/icons/calculator.svg";
import { CurrencyText } from "../../../../common/inputs";
import SupplierProgramContext from "../../../../../context/supplierProgramContext";

export default function Limit(props) {
  const {
    handleLimitChange,
    data,
    setData,
    validateDiscountRate,
    defaultRatearray,
    viewShow,
    editShow,
  } = useContext(SupplierProgramContext);

  // if (data?.program_limit) {
  //   setProceedNext(true);
  // } else {
  //   setProceedNext(false);
  // }

  useEffect(() => {
    if (editShow) {
      const updatedData = { ...data, update: true };
      setData(updatedData);
    } else {
    }
  }, [editShow]);
  useEffect(() => {
    validateDiscountRate();
  }, [handleLimitChange]);
  return (
    <div>
      <div className="row">
        <div className="col-lg-5">
          <div className="logo text-center mb-2 mt-2  ">
            <img src={Calculator} alt="Fifo Calculator" />
          </div>
          <div className="text-center">
            <h6>Program Limit</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. orem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </p>
          </div>
        </div>
        <div className="col-lg-7">
          <div>
            <div className="d-sm-flex w-100  py-3 align-items-center ">
              <div className="custom-control custom-radio custom-control-inline"></div>
              <div className="col-lg-10">
                <div className="border rounded-xl p-4 shadow-sm mb-3   font-weight-bold">
                  {data?.funded_by === "fifo" && (
                    <div class="card bg-light b-dashed aligin-center">
                      <div class="text-center">
                        <div class="card-body py-4">
                          <div class="row justify-content-center">
                            <div class="col-xl-10">
                              <h6 class="card-title text-secondary">
                                Current Availability
                              </h6>

                              <h4 class="text-secondary font-weight-bold   text-center p-2">
                                <span>
                                  $
                                  {defaultRatearray?.primary1Account === null
                                    ? "0"
                                    : defaultRatearray?.primary1Account}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-group p-2">
                    <label>Set the program limit.</label>
                    <div>
                      {" "}
                      <CurrencyText
                        placeholder="Program Limit"
                        name="program_limit"
                        isDisabled={viewShow === true}
                        onChangeEvent={(event, maskedvalue, floatvalue) => {
                          handleLimitChange(event, "limit", floatvalue);
                        }}
                        value={
                          defaultRatearray?.primary1Account === null &&
                          data.funded_by === "fifo"
                            ? 0
                            : data?.program_limit
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
